import { PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import config from "src/config";
import { dispatch } from "src/workers/common-worker";
import { ThemeRequest, ThemeSettings } from "./workers/types";
import logger from "../logger";
  
export type ThemeContextType = {
    theme: ThemeSettings;
    isThemeLoaded: boolean
};

const defaultThemeContext:ThemeContextType = {
    theme: new ThemeSettings(),
    isThemeLoaded: false
};
  
const ThemeContext = createContext<ThemeContextType>(defaultThemeContext);

export const ThemeContextProvider = ({children}: PropsWithChildren<any>): JSX.Element => {
    const location = useLocation();
    const { param } = useParams();
    const [theme, setTheme] = useState<ThemeSettings>(defaultThemeContext.theme);
    const [isThemeLoaded, setIsThemeLoaded] = useState<boolean>(false);
    const paymentsServiceUrl: string =  config.apis.paymentServiceUrl;
    const tokenMediatorUrl: string =  config.apis.tokenMediatorUrl;
    const worker: Worker = useMemo(
      () => new Worker(new URL("./workers/theme-worker.ts", import.meta.url)),
      []
    );

    let _logger = logger.child({
        hostedSessionID: param
    })
  
    useEffect(() => {
        const getTheme = async (param) => {
            if(!param){
                return;
            }
    
            const cId = location.hash.substring(1);
    
            if(!cId){
                return;
            }
    
            if(Worker){
              const hsID: string = param || "";
              const request: ThemeRequest = {
                  tokenMediatorUrl: tokenMediatorUrl,
                  paymentsServiceUrl: paymentsServiceUrl,
                  hostedSessionId: hsID,
                  configId: cId
              };
    
              await dispatch<ThemeRequest, any>(worker, request)
                    .then((response: ThemeSettings) => {
                        if(response instanceof Error){
                            _logger.error(response, "error building payment page");
                        }
                        _logger.debug(response, JSON.stringify(response))

                        setTheme(response ? response : defaultThemeContext.theme);
                        setIsThemeLoaded(true);
                    })
                    .catch((error) => {
                        _logger.child({
                            error: error instanceof Error ? error.message : JSON.stringify(error)
                        }).warn("an error occurred initializing theme, using default theme");
                        setTheme(defaultThemeContext.theme);
                        setIsThemeLoaded(true);
                    });
            }
        };

        getTheme(param);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
  
    return (
      <ThemeContext.Provider value={{theme: theme || defaultThemeContext.theme, isThemeLoaded: isThemeLoaded}}>
        {children}
      </ThemeContext.Provider>
    );
  };

  export const useTheme = () => {
    const context = useContext(ThemeContext);
  
    if (context === undefined) {
      throw new Error('useTheme must be used within a ThemeProvider');
    }
  
    return context;
  };