import { useEffect, useMemo, useState } from "react";
import PaymentButtons from "../components/PaymentButtons";
import { dispatch } from "src/workers/common-worker"
import { InitPaymentButtonsRequest, InitPaymentButtonsResponse } from "../workers/types";
import config from "src/config";
import { Nullable } from "src/workers/types";
import { ValidationError } from "class-validator";
import { PaymentButtons as Buttons } from "payments-ui-components/components/types";
import { useSearchParams } from "react-router-dom";
import { ButtonsComponentPage } from "../components/Types";
import { Assets, Branding, PresentationSettings } from "src/domain/PresentationSettings";

export default function PaymentButtonsPage() {
    const paymentsServiceUrl: string =  config.apis.paymentServiceUrl;
    const tokenMediatorUrl: string =  config.apis.tokenMediatorUrl;
    const [searchParams] = useSearchParams()
    const [validationErrors, setValidationErrors] = useState<ValidationError[]>([]);
    const [brandFastCheckoutButton, setBrandFastCheckoutButton] = useState<Buttons|null>();
    const [allowedFastCheckoutButtons, setAllowedFastCheckoutButtons] = useState<Buttons[]>();
    const [presentationSettings, setPresentationSettings] = useState<PresentationSettings>();
    const [provider, setProvider] = useState<string>();
    const [providerAccount, setProviderAccount] = useState<string>();
    const [amount, setAmount] = useState<number>()
    const [currency, setCurrency] = useState<string>()
    const [description, setDescription] = useState<string>()
    const [pageInitialized, setPageInitialized] = useState<boolean>(false);
    
    const worker: Worker = useMemo(
        () => new Worker(new URL("src/features/fast_payment_buttons/workers/init-payment-buttons.ts", import.meta.url)),
        []
    );

    useEffect(() => {
        document.body.style.overflow = "hidden";
        const tenantUrlParam = searchParams.get("tenant");
        const brandUrlParam = searchParams.get("brand");
        if(tenantUrlParam === null && brandUrlParam === null){
            return;
        }

        const pData = searchParams.get("data");
        if(!pData){
            return;
        }
        const paymentParams = atob(pData);
        const paymentQueryParams = new URLSearchParams(paymentParams);
        const amountUrlParam = paymentQueryParams.get("amount");
        const currencyUrlParam = paymentQueryParams.get("currency");
        const descriptionUrlParam = paymentQueryParams.get("description");
        const allowedPaymentButtonsParam = paymentQueryParams.get("allowedPaymentButtons");

        setAmount(Number(amountUrlParam));
        setCurrency(currencyUrlParam!);
        setDescription(decodeURIComponent(descriptionUrlParam!));
        const buttonsAvailable = allowedPaymentButtonsParam?.split(",") as Buttons[];
        setAllowedFastCheckoutButtons(buttonsAvailable);

        if (Worker) {
            const request: InitPaymentButtonsRequest = {
                tokenMediatorUrl: tokenMediatorUrl,
                paymentsServiceUrl: paymentsServiceUrl,
                tenantId: tenantUrlParam!,
                brandId: brandUrlParam!
            };

            dispatch<InitPaymentButtonsRequest, InitPaymentButtonsResponse>(worker, request)
                .then((response: Nullable<InitPaymentButtonsResponse>) => buildPage(response))
                .then((errors) => setValidationErrors(errors));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [worker]);


    const mapBrandInstrumentType = (instrumentType: string|null) => {

        if(instrumentType === "card"){
            return Buttons.Wallet;
        }

        if(instrumentType === "zahl_einfach"){
            return Buttons.ZahlEinfach;
        }

        return null;
    }

    async function buildPage(response) : Promise<ValidationError[]> {
        const providerAccount = response.provider_account;
        const provider = response.provider;
        setProvider(provider);
    
        if (response.fast_checkout_instrument_type){
            setBrandFastCheckoutButton(mapBrandInstrumentType(response.fast_checkout_instrument_type));
        }
        
        setPresentationSettings(mapSettings(response));
        setProviderAccount(providerAccount);
        setPageInitialized(true);
        return [];
    }

    function mapSettings(data){
        let presentationSettings = new PresentationSettings();

        let branding = new Branding();
        branding.button_color = data.presentation_settings.branding.button_color;
        presentationSettings.branding = branding;
        return presentationSettings;
    }

    let buttonsComponentPage = {
        brandFastCheckoutButton: brandFastCheckoutButton,
        allowedFastCheckoutButtons: allowedFastCheckoutButtons,
        provider: provider,
        presentationSettings: presentationSettings,
        providerAccount: providerAccount,
        amount: amount,
        currency: currency,
        description: description
    } as ButtonsComponentPage

    return (
        <div>
            {pageInitialized && buttonsComponentPage && (
                <PaymentButtons {...buttonsComponentPage}></PaymentButtons>
            )}
        </div>
    );
}

