import {useEffect} from "react";
import Grid from '@mui/material/Grid';
import {InstrumentHostedSession} from "src/domain/HostedSession";
import "src/App.css";
import '@payrails/web-sdk/payrails-styles.css';
import Payrails, {InitOptions, PayrailsEnvironment} from "@payrails/web-sdk";
import config from "src/config";
import logger from "src/logger";
import {useParams} from "react-router-dom";
import { paymentComponentInitializationFailed } from "src/metrics";

export default function SetupFormPayrails({instrumentHostedSession} : {instrumentHostedSession : InstrumentHostedSession}) {
    const StagingEnv: string = "staging";
    const { param } = useParams();

    let _logger = logger.child({
        hostedSessionID: param,
    })

    useEffect(() => {
        const loadSDKConfig = () => {

            let sessionData = {
                data: instrumentHostedSession.client_secret,
            }

            let options = {
                environment: (config.paywallEnv === StagingEnv || instrumentHostedSession.test_mode) ? PayrailsEnvironment.TEST : PayrailsEnvironment.PRODUCTION,
            }

            const payrailsClient = Payrails.Payrails.init(sessionData as InitOptions, options);

            const dropin = payrailsClient.dropin({
                styles:{
                    element: {
                        base: {
                            boxSizing: 'border-box'
                        }
                    },
                    authFailed: {
                        maxWidth: '100%'
                    },
                },
                paymentMethodsConfiguration: {
                    cards: {
                        showCardHolderName: true,
                        showStoreInstrumentCheckbox: false,
                        alwaysStoreInstrument: true,
                        showStoredInstruments:false
                    },
                },
                events: {
                    // @ts-ignore
                    onAuthorizeSuccess: () => {
                        _logger.info("setup authorization successful")
                        window.location.replace(instrumentHostedSession.success_url);
                    },
                    onAuthorizeFailed: () => {
                        _logger.warn("setup authorization failed")
                    },
                    onPaymentSessionExpired: () => {
                        _logger.warn("setup session expired")
                    },
                    onThreeDSecureChallenge: () => {
                        _logger.info("3D Secure challenge initiated")
                    }
                },
            });

            dropin.mount("#dropin-container");
            _logger.info("payrails component initialized");
        }

        try {
            loadSDKConfig();
        }catch (error) {
            _logger.child({
                error: error instanceof Error ? error.message : JSON.stringify(error)
            }).error("an error occurred during the payrails dropin initialization");

            paymentComponentInitializationFailed();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (instrumentHostedSession === null || instrumentHostedSession === undefined) {
        return <p>Loading..</p>;
    }

    return (
        <Grid>
            <div>
                <div id="dropin-container"/>
            </div>
            <div className="allow-text" style={{marginTop: "20px"}}>
                {/* to be shown only when it makes sense for the card to be used in off session */}
                By providing your card information, you allow TP Global Operations Limited to charge your card for future payments in accordance with their terms.
            </div>
        </Grid>
    );
}