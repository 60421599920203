import * as React from 'react'
import Typography from "@mui/material/Typography"
import logger from "src/logger";

type Props = {
    children: React.ReactNode;
}

type State = {
    hasError: boolean
}

export default class ErrorBoundary extends React.Component< Props, State > {
    constructor(props: Props ) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidMount() {
        window.onerror = (message, source, lineno, colno, error) => {
            logger.child({
                event: typeof message === 'object' ? JSON.stringify(message) : message,
                error: error instanceof Error ? error.message : null,
                stack: error instanceof Error ? error.stack : null
            }).error("window.onerror: an unexpected error occurred");
        };

        window.addEventListener('unhandledrejection', this.logUnhandledRejection);
    }

    componentWillUnmount() {
        window.removeEventListener('unhandledrejection', this.logUnhandledRejection);
    }

    logUnhandledRejection = (event: PromiseRejectionEvent) => {
        logger.child({
            event: JSON.stringify(event),
        }).error("an unhandled rejection occurred");
    }

    componentDidCatch(error: any, errorInfo: any) {
        this.setState({hasError: true});

        logger.child({
            error: error instanceof Error ? error.message : JSON.stringify(error),
            stack: error instanceof Error ? error.stack : null,
            errorInfo: JSON.stringify(errorInfo)
        }).error("componentDidCatch: an unexpected error occurred");
    }

    render() {
        if ( this.state.hasError ) {
            return (
                <div>
                    <Typography variant={'body1'}>
                        Oops! Something went really wrong
                    </Typography>
                </div>
            );
        }

        return <>{ this.props.children }</>;
    }
}
