declare global {
  interface Window {
    env: {
      PAYMENTS_PAYMENTS_SERVICE_URL: string,
      PAYMENTS_PAYMENTS_SERVICE_INTERNAL_URL: string,
      PAYMENTS_PAYWALL_TOKEN_MEDIATOR_URL: string,
      CONNECT_OIDC_TOKEN_URL: string,
      STRIPE_TEST_AU_API_KEY: string,
      STRIPE_AU_API_KEY: string,
      STRIPE_TEST_DE_API_KEY: string,
      STRIPE_DE_API_KEY: string,
      STRIPE_TEST_ES_API_KEY: string,
      STRIPE_ES_API_KEY: string,
      STRIPE_TEST_FR_API_KEY: string,
      STRIPE_FR_API_KEY: string,
      STRIPE_TEST_HK_API_KEY: string,
      STRIPE_HK_API_KEY: string,
      STRIPE_TEST_NL_API_KEY: string,
      STRIPE_NL_API_KEY: string,
      STRIPE_TEST_PL_API_KEY: string,
      STRIPE_PL_API_KEY: string,
      STRIPE_TEST_SG_API_KEY: string,
      STRIPE_SG_API_KEY: string,
      STRIPE_TEST_UK_API_KEY: string,
      STRIPE_UK_API_KEY: string,
      STRIPE_TEST_US_API_KEY: string,
      STRIPE_US_API_KEY: string,
      STRIPE_TEST_TPBR_API_KEY: string,
      STRIPE_TPBR_API_KEY: string,
      PAYWALL_ENV: string,
      LOG_LEVEL: string
    }
  }
}

const src = (window && window.env) || {};

const config = {
  keycloak: {
    tokenUrl: src.CONNECT_OIDC_TOKEN_URL || ""
  },
  apis: {
    paymentServiceUrl: src.PAYMENTS_PAYMENTS_SERVICE_URL,
    paymentServiceInternalUrl: src.PAYMENTS_PAYMENTS_SERVICE_INTERNAL_URL,
    tokenMediatorUrl: src.PAYMENTS_PAYWALL_TOKEN_MEDIATOR_URL,
  },
  gateway: {
    apiKeys: { 
      "AU": src.STRIPE_AU_API_KEY,
      "DE": src.STRIPE_DE_API_KEY,
      "ES": src.STRIPE_ES_API_KEY,
      "FR": src.STRIPE_FR_API_KEY,
      "HK": src.STRIPE_HK_API_KEY,
      "NL": src.STRIPE_NL_API_KEY,
      "PL": src.STRIPE_PL_API_KEY,
      "SG": src.STRIPE_SG_API_KEY,
      "UK": src.STRIPE_UK_API_KEY,
      "US": src.STRIPE_US_API_KEY,
      "TPBR": src.STRIPE_TPBR_API_KEY
    },
    testApiKeys: { 
      "AU": src.STRIPE_TEST_AU_API_KEY,
      "DE": src.STRIPE_TEST_DE_API_KEY,
      "ES": src.STRIPE_TEST_ES_API_KEY,
      "FR": src.STRIPE_TEST_FR_API_KEY,
      "HK": src.STRIPE_TEST_HK_API_KEY,
      "NL": src.STRIPE_TEST_NL_API_KEY,
      "PL": src.STRIPE_TEST_PL_API_KEY,
      "SG": src.STRIPE_TEST_SG_API_KEY,
      "UK": src.STRIPE_TEST_UK_API_KEY,
      "US": src.STRIPE_TEST_US_API_KEY,
      "TPBR": src.STRIPE_TEST_TPBR_API_KEY
    }
  },
  paywallEnv: src.PAYWALL_ENV,
  logLevel: src.LOG_LEVEL || "info"
}

export default config
